@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .btn-primary {
    @apply py-2 px-4 bg-primaryYellow text-white text-center font-semibold rounded-full shadow-md hover:bg-primaryBlue transition-all duration-100 focus:outline-none  hover:border-2;
    @apply xs:text-sm;
  }
}
